import useAuthStore from '@/stores/AuthStore'
import useConversationStore from '@/stores/ConversationStore'
import { useThemeContext } from '@/stores/ThemeContext'
import { DownOutlined } from '@ant-design/icons'
import { Avatar, Col, Dropdown, Layout, Menu, Row, Space, Typography, theme, Badge } from 'antd'
import 'dayjs/locale/zh-cn'
import { useEffect, useState } from 'react'
import { Link, NavLink, Outlet, useHref } from 'react-router-dom'

import '@/assets/App.css'
import AppLogo from '@/assets/highlights_travel_300_300.png'
import 'react-chat-elements/dist/main.css'
import ReloadPrompt from './ReloadPrompt';
import ClearCache from './ClearCache';
import PageSpy from './PageSpy';

import { BUILD_VERSION, BUILD_DATE } from '@/config'

const { Header, Footer, Content } = Layout
const { Title } = Typography

function DesktopApp() {

  const { colorPrimary } = useThemeContext()
  const loginUser = useAuthStore(state => state.loginUser)

  const href = useHref()

  const totalNotify = useConversationStore((state) => state.totalNotify)

  let defaultPath = '/order/follow'

  if (href !== '/') {
    const splitPath = href.split('/');
    if (splitPath.length > 2) {
      defaultPath = '/' + splitPath[1] + '/' + splitPath[2]
    }
  }

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  /**
   * 标签页标题闪烁
   */
  const [isTitleVisible, setIsTitleVisible] = useState(true);
  useEffect(() => {
    let interval;
    if (totalNotify > 0) {
      if ('setAppBadge' in navigator) {
        navigator.setAppBadge(totalNotify).catch((error) => {});
      }
      interval = setInterval(() => {
        document.title = isTitleVisible ? `🔔🔥💬【${totalNotify}条新消息】` : '______________';
        setIsTitleVisible(!isTitleVisible);
      }, 500);
    } else {
      document.title = '销售平台';
      if ('clearAppBadge' in navigator) {
        navigator.clearAppBadge().catch((error) => {});
      }
    }
    return () => clearInterval(interval);
  }, [totalNotify, isTitleVisible]);

  return (
    <Layout>
      <Header className='header' style={{ position: 'sticky', top: 0, zIndex: 2, width: '100%', background: 'white' }}>
        <Row gutter={{ md: 24 }} align='middle'>
          <Col flex='220px'>
            <NavLink to='/'>
              <img src={AppLogo} className='logo' alt='App logo' />
            </NavLink>
            <Title level={3}>销售平台</Title>
          </Col>
          <Col span={10}>
            <Menu
              mode='horizontal'
              selectedKeys={[defaultPath]}
              items={[
                { key: '/order/follow', label: <Link to='/order/follow'>订单跟踪</Link> },
                {
                  key: '/order/chat',
                  label: (
                    <Link to='/order/chat'>
                      在线聊天
                      <Badge
                        count={totalNotify > 0 ? totalNotify : undefined}
                        style={{
                          backgroundColor: '#52c41a',
                        }}
                      />
                    </Link>
                  ),
                },
                { key: '/callcenter/call', label: <Link to='/callcenter/call'>语音通话</Link> },
                { key: '/chat/history', label: <Link to='/chat/history'>聊天记录</Link> },
              ]}
            />
          </Col>
          <Col flex='auto' style={{ color: 'white', marginBottom: '0', display: 'flex', justifyContent: 'end' }}>
            <ReloadPrompt />
            <Dropdown
              menu={{
                items: [
                  {
                    label: <Link to='/account/profile'>个人资料</Link>,
                    key: '1',
                  },
                  { type: 'divider' },
                  { label: <ReloadPrompt force />, key: 'reload' },
                  { type: 'divider' },
                  { label: <ClearCache />, key: 'clearcache' },
                  { type: 'divider' },
                  {
                    label: <Link to='/p/dingding/logout'>退出</Link>,
                    key: '3',
                  },
                ],
              }}
              trigger={['click']}>
              <a onClick={(e) => e.preventDefault()} style={{ color: colorPrimary }}>
                <Space>
                  <Avatar src={loginUser.avatarUrl}>{loginUser?.username?.substring(1)}</Avatar>
                  {loginUser.username}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
          }}>
          <Outlet />
        </Content>
      </Layout>
      <Footer>桂林海纳国际旅行社有限公司 Version: {BUILD_VERSION}({BUILD_DATE}){' '}<PageSpy /></Footer>
    </Layout>
  );
}

export default DesktopApp
