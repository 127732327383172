import { Layout, Button } from 'antd';
import MessagesHeader from '@/views/Conversations/Online/MessagesHeader';
import MessagesWrapper from '@/views/Conversations/Online/MessagesWrapper';
import InputComposer from '@/views/Conversations/Online/InputComposer';
import { UnorderedListOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Content, Header, Footer } = Layout;

function Chat() {
  const navigate = useNavigate();
  return (
    <>
      <Layout className='h-full chatwindow-wrapper mobilechat-wrapper' style={{ maxHeight: 'calc(100vh - 20px)', height: 'calc(100vh - 20px)', minWidth: '360px' }}>
        <Header className=' px-2 ant-layout-sider-light ant-card h-auto flex justify-between gap-1 items-center'>
          <Button type='text' icon={<MenuFoldOutlined />} onClick={() => navigate('/m/conversation')} className=' rounded-none rounded-l' />
          <MessagesHeader />
          <Button type='text' icon={<MenuUnfoldOutlined />} onClick={() => navigate('/m/order')} className=' rounded-none rounded-r' />
        </Header>
        <Content className='flex-grow bg-whatsapp-bg relative'>
          <MessagesWrapper />
        </Content>
        <Footer className='ant-layout-sider-light p-0'>
          <InputComposer mobile />
        </Footer>
      </Layout>
    </>
  );
}

export default Chat;
