
import { BUILD_VERSION } from '@/config'

const customHeaders = []

// 添加 HTTP Reuqest 自定义头部
export function appendRequestHeader(n, v) {
  customHeaders.push({
    name: n,
    value: v
  })
}

function getRequestHeader() {
  return customHeaders.reduce((acc, item) => {
    acc[item.name] = item.value;
    return acc;
  }, {});
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    const message =
      'Fetch error: ' + response.url + ' ' + response.status + ' (' +
      response.statusText + ')'
    const error = new Error(message)
    error.response = response
    throw error
  }
}

export function fetchText(url) {
  const headerObj = getRequestHeader()
  return fetch(url, {
    method: 'GET',
    headers: {
      'X-Web-Version': BUILD_VERSION,
      ...headerObj
    }
  }).then(checkStatus)
    .then(response => response.text())
    .catch(error => {
      throw error
    })
}

export function fetchJSON(url, data) {
  const params = data ? new URLSearchParams(data).toString() : '';
  const ifp = url.includes('?') ? '&' : '?';
  const headerObj = getRequestHeader()
  return fetch(`${url}${ifp}${params}`, {
    method: 'GET',
    headers: {
      'X-Web-Version': BUILD_VERSION,
      ...headerObj
    }
  }).then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      throw error;
    });
}

export function postForm(url, data) {
  const headerObj = getRequestHeader()
  return fetch(url, {
    method: 'POST',
    body: data,
    headers: {
      'X-Web-Version': BUILD_VERSION,
      ...headerObj
    }
  }).then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      throw error
    })
}

export function postJSON(url, obj) {
  const headerObj = getRequestHeader()
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(obj),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'X-Web-Version': BUILD_VERSION,
      ...headerObj
    }
  }).then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      throw error
    })
}

export function postStream(url, obj) {
  const headerObj = getRequestHeader()
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(obj),
    headers: {
      'Content-type': 'application/octet-stream',
      'X-Web-Version': BUILD_VERSION,
      ...headerObj
    }
  }).then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      throw error
    })
}
